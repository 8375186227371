<template>
  <!-- Orders History Timeline Card -->
  <a-card
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ paddingTop: '12px' }"
  >
  </a-card>
  <!-- / Orders History Timeline Card -->
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: { PulseLoader },
  computed: {},
  created() {},
  mounted() {
    this.updateGameLive();
  },
  data() {
    return {};
  },
  methods: {
    updateGameLive() {
      // var token = this.$store.getters.getToken.split(" ")[1];
      // this.sockets.subscribe(`game-${this.$route.params.game}-live`, (data) => {
      //   console.log("game_live", data);
      // });
    },
  },
};
</script>
