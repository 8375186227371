var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[
      _vm.gamePrediction.prediction != '-'
        ? _vm.gamePrediction.prediction === 'PLAYER' ||
          _vm.gamePrediction.prediction === 'DRAGON'
          ? 'border-player'
          : 'border-banker'
        : 'border-loading' ],staticStyle:{"background-color":"transparent"},attrs:{"bordered":false}},[_c('a-card',{staticStyle:{"background-color":"#1b1b1b"},attrs:{"bordered":false}},[(_vm.gameState == 'PLAYING')?_c('span',{staticClass:"blink_me card-content"},[_c('p',{staticClass:"center",staticStyle:{"background-color":"green","width":"70px","border-radius":"10px"}},[_vm._v(" กำลังเล่น ")])]):_c('span',{staticClass:"card-content"},[_c('p',{staticClass:"center",staticStyle:{"width":"70px","border-radius":"10px"}},[_c('pulse-loader',{attrs:{"color":"#000","size":"6px"}})],1)]),(_vm.gameType === 'BACCARAT' || _vm.gameType === 'DRAGON_TIGER')?_c('div',[_c('div',{staticClass:"row center mt-15"},[_c('div',{staticClass:"board-half"},[_c('p',{style:([
                _vm.gameType === 'BACCARAT'
                  ? { color: '#3358ff' }
                  : { color: '#aa1100' } ])},[_vm._v(" "+_vm._s(_vm.gameType === "BACCARAT" ? "PLAYER" : "DRAGON")+" ")]),_c('div',{staticClass:"tree-card"},[(_vm.gameType === 'BACCARAT')?_c('card',{attrs:{"gameType":'player',"cards":_vm.gameLive.playerCards[2] || '',"isWin":_vm.gameLive.winner.toString() === 'PLAYER' ||
                  _vm.gameLive.winner.toString() === 'DRAGON'
                    ? true
                    : false}}):_vm._e(),(_vm.gameType === 'BACCARAT')?_c('card',{attrs:{"gameType":'player',"cards":_vm.gameLive.playerCards[1] || '',"isWin":_vm.gameLive.winner.toString() === 'PLAYER' ||
                  _vm.gameLive.winner.toString() === 'DRAGON'
                    ? true
                    : false}}):_vm._e(),_c('card',{attrs:{"gameType":_vm.gameType === 'BACCARAT' ? 'player' : 'dragon',"cards":_vm.gameLive.playerCards[0] || '',"isWin":_vm.gameLive.winner.toString() === 'PLAYER' ||
                  _vm.gameLive.winner.toString() === 'DRAGON'
                    ? true
                    : false}})],1),_c('div',{class:[
                'box  text-white',
                _vm.gameType === 'BACCARAT'
                  ? 'bg-score-player'
                  : 'bg-score-banker' ]},[_vm._v(" "+_vm._s(_vm.gameLive.playerScore)+" ")])]),_c('div',{staticClass:"board-half"},[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("สถานะ")]),_c('div',{staticClass:"pr-25 pl-25",staticStyle:{"text-align":"center"}},[(_vm.gameState == 'PLAYING')?_c('span',{staticClass:"blink_me card-content"},[(_vm.setTimer)?_c('p',[_vm._v(_vm._s(_vm.timer))]):_vm._e(),(!_vm.setTimer)?_c('pulse-loader',{attrs:{"color":"#000","size":"10px"}}):_vm._e()],1):_vm._e(),(_vm.gameState == 'RESULTED')?_c('span',[_c('p',[_vm._v("ออกผล")])]):_vm._e(),(_vm.gameState == 'RESULTING' || _vm.gameState == 'WAITING')?_c('pulse-loader',{attrs:{"color":"#000","size":"10px"}}):_vm._e()],1)]),_c('div',{staticClass:"board-half"},[_c('p',{style:(_vm.gameType === 'BACCARAT'
                  ? { color: '#aa1100' }
                  : { color: '#3358ff' })},[_vm._v(" "+_vm._s(_vm.gameType === "BACCARAT" ? "BANKER" : "TIGER")+" ")]),_c('div',{staticClass:"tree-card"},[_c('card',{attrs:{"gameType":_vm.gameType === 'BACCARAT' ? 'banker' : 'tiger',"cards":_vm.gameLive.bankerCards[0] || '',"isWin":_vm.gameLive.winner.toString() === 'BANKER' ||
                  _vm.gameLive.winner.toString() === 'TIGER'
                    ? true
                    : false}}),(_vm.gameType === 'BACCARAT')?_c('card',{attrs:{"gameType":'banker',"cards":_vm.gameLive.bankerCards[1] || '',"isWin":_vm.gameLive.winner.toString() === 'BANKER' ||
                  _vm.gameLive.winner.toString() === 'TIGER'
                    ? true
                    : false}}):_vm._e(),(_vm.gameType === 'BACCARAT')?_c('card',{attrs:{"gameType":'banker',"cards":_vm.gameLive.bankerCards[2] || '',"isWin":_vm.gameLive.winner.toString() === 'BANKER' ||
                  _vm.gameLive.winner.toString() === 'TIGER'
                    ? true
                    : false}}):_vm._e()],1),_c('div',{class:[
                'box  text-white',
                _vm.gameType === 'BACCARAT'
                  ? 'bg-score-banker'
                  : 'bg-score-player' ]},[_vm._v(" "+_vm._s(_vm.gameLive.bankerScore)+" ")])])]),_c('a-row',{staticClass:"mt-10",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12,"lg":8,"xl":8}},[_c('div',{staticClass:"card-client"},[_c('p',{staticClass:"name-client"},[_vm._v("โต๊ะที่")]),_c('div',{staticClass:"social-media"},[_c('span',[_vm._v(" "+_vm._s(_vm.gamePrediction.tableId)+" ")])])])]),_c('a-col',{attrs:{"span":12,"lg":8,"xl":8}},[_c('div',{staticClass:"card-client"},[_c('p',{staticClass:"name-client"},[_vm._v("ตาที่")]),_c('div',{staticClass:"social-media"},[_c('span',[_vm._v(" "+_vm._s(_vm.gamePrediction.gameRound)+" ")])])])]),_c('a-col',{attrs:{"span":12,"lg":8,"xl":8,"xs":24,"md":24}},[_c('div',{staticClass:"card-client"},[_c('p',{staticClass:"name-client"},[_vm._v("วิเคราะห์ผล")]),_c('div',{staticClass:"social-media"},[_vm._v(_vm._s(_vm.gamePrediction.prediction))])])])],1)],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }