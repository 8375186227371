<template>
  <div>
    <div class="modals" v-if="showModal">
      <article class="modals-container">
        <section class="modals-container-body rtf">
          <p>ยืนยันที่จะหยุดบอท</p>
        </section>
        <footer class="modals-container-footer">
          <button class="button is-primary" @click="stopBot()">ยืนยัน</button>
          <button class="button is-ghost" @click="showModal = false">
            ปิด
          </button>
        </footer>
      </article>
    </div>
    <!-- <template id="modal-template" v-if="showModal"> -->
    <!-- <template id="modal-template">
      <div class="modal">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-body">ยืนยันที่จะหยุดบอท</div>
            <div class="modal-footer">
              <button @click="stopBot()">ยืนยัน</button>
              <button @click="showModal = false">ปิด</button>
            </div>
          </div>
        </div>
      </div>
    </template> -->
    <div class="wallet_fixed fade-in" v-if="!hidden">
      <div class="hidden_tag">
        <h5 class="m-0" @click="switchConsole">✖</h5>
      </div>
      <a-col :span="24" :md="24">
        <div style="width: 240px" :bordered="false">
          <div class="card-title">
            <p>กระเป๋าเงิน</p>
            <h4 class="m-1"><Balance /></h4>
          </div>

          <ConsoleButton
            v-if="botStatus === 'NULL'"
            :title="`สร้างบอท`"
            :action="`sendCreateBot`"
            :color="`primary`"
            @sendCreateBot="sendCreateBot"
          />
          <div v-else>
            <a-row class="m-5 mb-20">
              <!-- <ConsoleToggle
              :title="`แดนลบ`"
              v-if="gameType === 'ROULETTE'"
            /> -->
              <!-- {{ betType }} -->

              <ConsoleToggle
                :title="`แทงเสมอ`"
                :action="`sendTie`"
                :betType="betType"
                :checkedStatus="betType === 'TIE' ? true : false"
                @sendTie="sendTie"
                v-if="gameType != 'ROULETTE'"
              />
              <ConsoleToggle
                :title="`แทงสวน`"
                :action="`sendOpposite`"
                :betType="betType"
                :checkedStatus="betType === 'OPPOSITE' ? true : false"
                @sendOpposite="sendOpposite"
              />
              <ConsoleToggle
                :title="`แทงศูนย์`"
                :action="`sendZero`"
                :betType="betType"
                :checkedStatus="betType === 'ZERO' ? true : false"
                @sendZero="sendZero"
                v-if="gameType === 'ROULETTE'"
              />
            </a-row>
            <ConsoleButton
              v-if="isBotStatus === 'IDLE'"
              :title="`เริ่มบอท`"
              :action="`startBot`"
              :color="`primary`"
              :customStyle="`start`"
              @startBot="startBot"
            />
            <ConsoleButton
              v-if="isBotStatus === 'RUNNING'"
              :title="`พักบอท`"
              :action="`pauseBot`"
              :color="`primary`"
              :customStyle="`pause`"
              @pauseBot="pauseBot"
            />
            <ConsoleButton
              v-if="isBotStatus === 'PAUSED'"
              :title="`เล่นต่อ`"
              :action="`resumeBot`"
              :color="`primary`"
              :customStyle="`resume`"
              @resumeBot="resumeBot"
            />
            <ConsoleButton
              :title="`หยุดบอท`"
              :action="`stopBotModal`"
              :color="`danger`"
              @stopBotModal="stopBotModal"
            />
          </div>
        </div>
      </a-col>
    </div>
    <a-button class="sidepocket" v-if="hidden" @click="switchConsole">
      ⬆ กระเป๋าเงิน
    </a-button>
  </div>
</template>
<script>
import ConsoleButton from "./ConsoleButton";
import ConsoleToggle from "./ConsoleToggle";
import Balance from "@/views/components/wallet/Balance.vue";

export default {
  components: { ConsoleButton, ConsoleToggle, Balance },
  computed: {},
  props: {
    botStatus: { type: String, default: "NULL" },
    gameType: { type: String },
    betType: {
      type: String,
      default: "NORMAL",
    },
  },
  created() {},

  data() {
    return {
      hidden: false,
      errorMsg: "",
      isBotStatus: "",
      showModal: false,
    };
  },
  methods: {
    sendTie() {
      if (this.betType === "TIE") {
        this.$http
          .post(
            `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/customize?isMock=${this.$store.getters.getMock}`,
            { betMoneyType: "NORMAL" }
          )
          .then(({}) => {
            this.$emit("getBot");
          })
          .catch((err) => {
            return err;
          });
      } else {
        this.$http
          .post(
            `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/customize?isMock=${this.$store.getters.getMock}`,
            { betMoneyType: "TIE" }
          )
          .then(({}) => {
            this.$emit("getBot");
          })
          .catch((err) => {
            return err;
          });
      }
    },
    sendOpposite() {
      if (this.betType === "OPPOSITE") {
        this.$http
          .post(
            `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/customize?isMock=${this.$store.getters.getMock}`,
            { betMoneyType: "NORMAL" }
          )
          .then(({}) => {
            this.$emit("getBot");
          })
          .catch((err) => {
            return err;
          });
      } else {
        this.$http
          .post(
            `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/customize?isMock=${this.$store.getters.getMock}`,
            { betMoneyType: "OPPOSITE" }
          )
          .then(({}) => {
            this.$emit("getBot");
          })
          .catch((err) => {
            return err;
          });
      }
    },
    sendZero() {
      if (this.betType === "ZERO") {
        this.$http
          .post(
            `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/customize?isMock=${this.$store.getters.getMock}`,
            { betMoneyType: "NORMAL" }
          )
          .then(({}) => {
            this.$emit("getBot");
          })
          .catch((err) => {
            return err;
          });
      } else {
        this.$http
          .post(
            `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/customize?isMock=${this.$store.getters.getMock}`,
            { betMoneyType: "ZERO" }
          )
          .then(({}) => {
            this.$emit("getBot");
          })
          .catch((err) => {
            return err;
          });
      }
    },

    switchConsole() {
      this.hidden ? (this.hidden = false) : (this.hidden = true);
    },
    sendCreateBot() {
      this.$emit("sendCreateBot");
    },
    updataBotStatus(val) {
      this.isBotStatus = val;
    },
    stopBotModal() {
      this.showModal = true;
    },
    //Shorten
    stopBot() {
      this.showModal = false;
      this.$http
        .post(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/stop?isMock=${this.$store.getters.getMock}`,
          {}
        )
        .then(({ data }) => {
          window.location.reload();
        })
        .catch((err) => {
          this.showModal = true;
          return;
        });
    },
    startBot() {
      this.$http
        .post(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/start?isMock=${this.$store.getters.getMock}`,
          {}
        )
        .then(({ data }) => {
          this.isBotStatus = "RUNNING";
          this.$emit("startBot");
          this.$forceUpdate();
        })
        .catch((err) => {
          return;
        });
    },
    pauseBot() {
      this.$http
        .post(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/pause?isMock=${this.$store.getters.getMock}`,
          {}
        )
        .then(({ data }) => {
          this.isBotStatus = "PAUSED";
          this.$forceUpdate();
        })
        .catch((err) => {
          return;
        });
    },
    resumeBot() {
      this.$http
        .post(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.$route.params.game}/resume?isMock=${this.$store.getters.getMock}`,
          {}
        )
        .then(({ data }) => {
          this.isBotStatus = "RUNNING";
          this.$forceUpdate();
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style>
.hidden_tag {
  width: 15%;
  position: absolute;
  display: flex;
  z-index: 10000;
  top: 10px;
  right: -10px;
  cursor: pointer;
}
.wallet_fixed {
  /* max-width: 400px; */
  position: fixed;
  display: flex;
  z-index: 10000;
  bottom: 0;
  right: 0;
  border-radius: 14px 0px 0px 0px;
  background-color: #1e1e1e;
  padding: 10px;
  border: 2px solid white;

  /* left: 0; */
}
.shake-in {
  animation: shake 0.5s;
}
.fade-in {
  animation: fadeIn 0.5s;
}
@media (max-width: 469px) {
  .wallet_fixed {
    right: 0px;
    /* left: 0; */
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    bottom: -40px;
  }

  100% {
    bottom: 0;
  }
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
