<template>
  <a-col :span="24" :md="8">
    <p class="m-0">{{ title }}</p>
    <a-switch @change="callAction" :checked="checkedStatus" />
  </a-col>
</template>
<script>
export default {
  components: {},
  computed: {},
  props: {
    title: {
      type: String,
      default: "default",
    },
    action: { type: String, default: "" },
    betType: { type: String, default: "Normal" },
    checkedStatus: { type: Boolean, default: true },
  },
  mounted() {},

  data() {
    return {
      checked: false,
    };
  },
  methods: {
    callAction() {
      this.$emit(this.action, "test");
    },
  },
};
</script>
