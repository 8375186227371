<template id="modal-template ">
  <div class="modal">
    <div class="modal-wrapper">
      <div class="modal-container scroller">
        <h6>ประวัติการเล่น {{ gameType }}</h6>
        <a-button
          block
          style="background-color: #3358ff"
          @click="$emit('closeModal')"
          >กลับหน้าแรก
        </a-button>

        <a-row :gutter="24" type="flex" align="stretch" class="mt-10">
          <a-col :span="24" :lg="6" :sm="24" class="mb-24">
            <a-card :bordered="false">
              <a-table
                :columns="columns"
                :data-source="botData"
                :pagination="true"
              >
                <template slot="createdAt" slot-scope="createdAt">
                  <div
                    :class="[
                      botSetting.createdAt === createdAt ? 'table-active' : '',
                    ]"
                  >
                    <div>
                      <h6>
                        {{
                          new Date(createdAt).toLocaleString("th-TH", {
                            timeZone: "Asia/Bangkok",
                          })
                        }}
                      </h6>
                    </div>
                  </div>
                </template>

                <template slot="id" slot-scope="id">
                  <!-- <a-tag
                    class="tag-status"
                    :class="status ? 'ant-tag-primary' : 'ant-tag-muted'"
                  >
                    {{ status ? "ONLINE" : "OFFLINE" }}
                  </a-tag> -->
                  <a-button
                    style="background-color: #3358ff"
                    :href="`#room/${gameType}`"
                    @click="getBotBetTransaction(id)"
                    >ดูข้อมูล</a-button
                  >
                </template>
              </a-table>
            </a-card>
          </a-col>
          <a-col :span="24" :lg="18" class="mb-24">
            <a-card :bordered="false" style="height: 100%">
              <!-- <p>ไม่พบข้อมูล</p> -->
              <ChartProfit
                :id="`room/${gameType}`"
                ref="chart_profit"
                :height="140"
                class="p-0"
              />
              <!-- {{ botSetting }}{{ botTransaction }} -->

              <!-- <a-col :span="24" :xl="6" class="mb-24">
                  <a-card
                    :bordered="false"
                    class="header-solid h-full"
                    :bodyStyle="{ paddingTop: '12px' }"
                  >
                    <h6>การตั้งค่า</h6>
                    <a-timeline>
                      <a-timeline-item color="green">
                        Game
                        <p>{{ botSetting.gameType }}</p>
                      </a-timeline-item>
                      <a-timeline-item color="green">
                        รูปแบบการเล่น
                        <p>{{ botSetting.playerBetType }}</p>
                      </a-timeline-item>
                      <a-timeline-item color="green">
                        การเดินเงิน
                        <p>{{ botSetting.betMoneyType }}</p>
                      </a-timeline-item>
                      <a-timeline-item color="green">
                        ชิพเริ่มต้น
                        <p>{{ botSetting.betAmount }}</p>
                      </a-timeline-item>
                      <a-timeline-item color="green">
                        กำไรเป้าหมาย

                        <p>{{ profit.amount }}({{ profit.percent }}%)</p>
                      </a-timeline-item>
                      <a-timeline-item color="green">
                        กำหนดขาดทุนไม่เกิน
                        <p>{{ loss.amount }}({{ loss.percent }}%)</p>
                      </a-timeline-item>
                    </a-timeline>
                  </a-card>
                </a-col> -->
              <a-row :gutter="24" class="mt-10">
                <a-col :span="12" :lg="5" :xl="5">
                  <div class="card-client m-0">
                    <p class="name-client">รูปแบบการเล่น</p>
                    <div class="social-media">
                      <span> {{ botSetting.playerBetType }} </span>
                    </div>
                  </div>
                </a-col>
                <a-col :span="12" :lg="5" :xl="5">
                  <div class="card-client m-0">
                    <p class="name-client">การเดินเงิน</p>
                    <div class="social-media">
                      <span> {{ botSetting.betMoneyType }} </span>
                    </div>
                  </div>
                </a-col>
                <a-col :span="12" :lg="4" :xl="4">
                  <div class="card-client m-0">
                    <p class="name-client">ชิพเริ่มต้น</p>
                    <div class="social-media">{{ botSetting.betAmount }}</div>
                  </div>
                </a-col>
                <a-col :span="12" :lg="5" :xl="5">
                  <div class="card-client m-0">
                    <p class="name-client">กำไรเป้าหมาย</p>
                    <div class="social-media">
                      {{ profit.amount }}({{ profit.percent }}%)
                    </div>
                  </div>
                </a-col>
                <a-col :span="12" :lg="5" :xl="5">
                  <div class="card-client m-0">
                    <p class="name-client">กำหนดขาดทุนไม่เกิน</p>
                    <div class="social-media">
                      {{ loss.amount }}({{ loss.percent }}%)
                    </div>
                  </div>
                </a-col>
              </a-row>
              <a-col :span="24" :lg="24" class="mb-24">
                <CardHistory
                  :data="botTransaction"
                  :columns="tableColumns"
                  :gameType="gameType"
                  :hideTitle="true"
                ></CardHistory>
              </a-col>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import ChartProfit from "@/views/components/chart/ChartProfit";

const columns = [
  {
    title: "เวลาที่สร้าง",
    dataIndex: "createdAt",
    scopedSlots: { customRender: "createdAt" },
  },

  {
    title: "",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
];

import Loading from "../../components/Loading.vue";
import CardHistory from "../CardHistory.vue";

export default {
  components: {
    Loading,
    ChartProfit,
    CardHistory,
  },
  props: {
    gameType: { type: String },
  },
  created() {
    this.getBot();
    // this.getBotBetTransaction();
  },
  watch: {},
  data() {
    return {
      showModal: false,
      columns,
      timelineReverse: false,
      botData: [],
      botSetting: [],
      botTransaction: [],
      profit: {},
      loss: {},
      tableColumns: [
        {
          title: "รอบที่",
          dataIndex: "index",
        },
        {
          title: "โตีะที่",
          dataIndex: "tableId",
        },
        {
          title: "ฝั่งที่เล่น",
          dataIndex: "playerType",
        },
        {
          title: "ชิพที่ลง",
          dataIndex: "betAmount",
        },

        {
          title: "กำไร",
          dataIndex: "profit",
          class: "font-bold text-muted text-sm",
        },
        {
          title: "เวลา",
          dataIndex: "createdAt",

          scopedSlots: { customRender: "createdAt" },
          class: "font-bold text-muted text-sm",
        },
        // {
        //   title: "ผลการเล่น",
        //   dataIndex: "isWin",
        //   class: "font-bold text-muted text-sm",
        // },
      ],
    };
  },
  methods: {
    getBotBetTransaction(val) {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.gameType}/${val}`
        )
        .then(({ data }) => {
          this.botSetting = data;
          this.loss = data.lossLimit;
          this.profit = data.profitTarget;
          this.$http
            .get(
              `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.gameType}/${val}/transaction?limit=0&isMock=${this.$store.getters.getMock}`
            )
            .then(({ data }) => {
              this.botTransaction = data;
              for (var i = 0; i < this.botTransaction.length; i++) {
                this.botTransaction[i]["index"] =
                  this.botTransaction.length - i;
              }
              this.$refs.chart_profit.getChart(this.botTransaction);
            })
            .catch((err) => {
              return err;
            });
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },
    getBot() {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/api/bot/${this.gameType}/history?limit=0&isMock=${this.$store.getters.getMock}`
        )
        .then(({ data }) => {
          this.botData = data;
        })
        .catch((err) => {
          return err;
        });
    },
  },
};
</script>

<style lang="scss" style scoped>
.scroller {
  //   min-width: 40px;
  height: 700px;
  overflow-y: scroll;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}
.table-active {
  background-color: #3358ff;
  border-radius: 4px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease;
  z-index: 1000000001;

  &-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &-container {
    border-radius: 5px;
    background-color: black;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    margin: 0 auto;
    padding: 20px 30px;
  }
}
@media (max-width: 482px) {
  .scroller {
    height: 500px;
  }
}
</style>
