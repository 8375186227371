var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{attrs:{"id":"rouletteBoard"}},[_c('a-card',{staticClass:"center",staticStyle:{"background-color":"#1b1b1b"},attrs:{"bordered":false}},[_c('div',{staticClass:"board-half"},[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("สถานะ")]),_c('div',{staticClass:"pr-25 pl-25",staticStyle:{"text-align":"center"}},[(_vm.gameState == 'PLAYING')?_c('span',{staticClass:"blink_me card-content"},[_c('p',{staticClass:"center",staticStyle:{"background-color":"green","width":"70px","border-radius":"10px"}},[_vm._v(" กำลังเล่น ")])]):_vm._e(),(_vm.gameState == 'RESULTED')?_c('span',[_c('p',[_vm._v("ออกผล")])]):_vm._e(),(_vm.gameState == 'RESULTING' || _vm.gameState == 'WAITING')?_c('pulse-loader',{staticClass:"mb-15",attrs:{"color":"#000","size":"10px"}}):_vm._e()],1)]),_c('div',{staticClass:"container-main"},[_c('div',{staticClass:"container-first"},[_c('div',{class:[
              'zero-item',
              _vm.gameLive.winner[0] === 'ZERO' ? 'show' : '' ]},[_c('div',{staticClass:"value"},[_vm._v("0")])]),_vm._l((36),function(i){return _c('div',{key:i},[_c('div',{class:[
                _vm.red_number.includes(i) ? 'red-item' : 'black-item',
                _vm.gameLive.number === i ? 'show' : '' ]},[_c('div',{staticClass:"value"},[_vm._v(_vm._s(i))])])])}),_c('div',{class:[
              'column-item',
              _vm.col1.includes(_vm.gameLive.number) ? 'show' : '' ]},[_c('div',[_vm._v("col1")])]),_c('div',{class:[
              'column-item',
              _vm.col2.includes(_vm.gameLive.number) ? 'show' : '' ]},[_c('div',[_vm._v("col2")])]),_c('div',{class:[
              'column-item',
              _vm.col3.includes(_vm.gameLive.number) ? 'show' : '' ]},[_c('div',[_vm._v("col3")])])],2),_c('div',{staticClass:"container-second"},[_c('div',{class:[
              'doz-item',
              _vm.gameLive.winner.includes('DOZEN1') ? 'show' : '' ]},[_c('div',[_vm._v("1st 12")])]),_c('div',{class:[
              'doz-item',
              _vm.gameLive.winner.includes('DOZEN2') ? 'show' : '' ]},[_c('div',[_vm._v("2nd 12")])]),_c('div',{class:[
              'doz-item',
              _vm.gameLive.winner.includes('DOZEN3') ? 'show' : '' ]},[_c('div',[_vm._v("3rd 12")])])]),_c('div',{staticClass:"container-third"},[_c('div',{class:[
              'outside-section',
              _vm.gameLive.number >= 1 && _vm.gameLive.number <= 18 ? 'show' : '' ]},[_c('div',[_vm._v("1-18")])]),_c('div',{class:[
              'outside-section',
              _vm.gameLive.winner.includes('EVEN') ? 'show' : '' ]},[_c('div',[_vm._v("EVEN")])]),_c('div',{class:[
              'outside-section',
              _vm.gameLive.winner.includes('RED') ? 'show' : '' ]},[_c('div',[_c('div',{staticClass:"rhomb-red"})])]),_c('div',{class:[
              'outside-section',
              _vm.gameLive.winner.includes('BLACK') ? 'show' : '' ]},[_c('div',[_c('div',{staticClass:"rhomb-black"})])]),_c('div',{class:[
              'outside-section',
              _vm.gameLive.winner.includes('ODD') ? 'show' : '' ]},[_c('div',[_vm._v("ODD")])]),_c('div',{class:['outside-section', _vm.gameLive.number >= 19 ? 'show' : '']},[_c('div',[_vm._v("19-36")])])]),_c('div',{staticClass:"mb-24"})])])],1),_c('a-row',{staticClass:"mt-10",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12,"lg":4,"xl":4}},[_c('div',{staticClass:"card-client"},[_c('p',{staticClass:"name-client"},[_vm._v("โต๊ะที่")]),_c('div',{staticClass:"social-media"},[_c('span',[_vm._v(" "+_vm._s(_vm.gamePrediction.tableId)+" ")])])])]),_c('a-col',{attrs:{"span":12,"lg":4,"xl":4}},[_c('div',{staticClass:"card-client"},[_c('p',{staticClass:"name-client"},[_vm._v("ตาที่")]),_c('div',{staticClass:"social-media"},[_c('span',[_vm._v(" "+_vm._s(_vm.gamePrediction.gameRound)+" ")])])])]),_c('a-col',{attrs:{"span":12,"lg":16,"xl":16,"xs":24,"md":24}},[_c('div',{staticClass:"card-client"},[_c('p',{staticClass:"name-client"},[_vm._v("วิเคราะห์ผล")]),_c('div',{staticClass:"social-media"},[_vm._v(" "+_vm._s(_vm.gamePrediction.prediction.playerType.toString())+"|"+_vm._s(_vm.gamePrediction.prediction.number)+" ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }