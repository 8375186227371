<template>
  <div>
    <section id="rouletteBoard">
      <a-card
        :bordered="false"
        style="background-color: #1b1b1b"
        class="center"
      >
        <div class="board-half">
          <p style="text-align: center">สถานะ</p>
          <div style="text-align: center" class="pr-25 pl-25">
            <span v-if="gameState == 'PLAYING'" class="blink_me card-content">
              <p
                class="center"
                style="
                  background-color: green;
                  width: 70px;
                  border-radius: 10px;
                "
              >
                กำลังเล่น
              </p>

              <!-- <p v-if="setTimer">{{ timer }}</p>
              <pulse-loader
                color="#000"
                size="10px"
                v-if="!setTimer"
              ></pulse-loader> -->
            </span>
            <span v-if="gameState == 'RESULTED'"><p>ออกผล</p> </span>
            <pulse-loader
              class="mb-15"
              color="#000"
              size="10px"
              v-if="gameState == 'RESULTING' || gameState == 'WAITING'"
            ></pulse-loader>
          </div>
        </div>
        <div class="container-main">
          <div class="container-first">
            <div
              :class="[
                'zero-item',
                gameLive.winner[0] === 'ZERO' ? 'show' : '',
              ]"
            >
              <div class="value">0</div>
            </div>
            <div v-for="i in 36" :key="i">
              <div
                :class="[
                  red_number.includes(i) ? 'red-item' : 'black-item',
                  gameLive.number === i ? 'show' : '',
                ]"
              >
                <div class="value">{{ i }}</div>
              </div>
            </div>
            <div
              :class="[
                'column-item',
                col1.includes(gameLive.number) ? 'show' : '',
              ]"
            >
              <div>col1</div>
            </div>
            <div
              :class="[
                'column-item',
                col2.includes(gameLive.number) ? 'show' : '',
              ]"
            >
              <div>col2</div>
            </div>
            <div
              :class="[
                'column-item',
                col3.includes(gameLive.number) ? 'show' : '',
              ]"
            >
              <div>col3</div>
            </div>
          </div>
          <div class="container-second">
            <div
              :class="[
                'doz-item',
                gameLive.winner.includes('DOZEN1') ? 'show' : '',
              ]"
            >
              <div>1st 12</div>
            </div>
            <div
              :class="[
                'doz-item',
                gameLive.winner.includes('DOZEN2') ? 'show' : '',
              ]"
            >
              <div>2nd 12</div>
            </div>
            <div
              :class="[
                'doz-item',
                gameLive.winner.includes('DOZEN3') ? 'show' : '',
              ]"
            >
              <div>3rd 12</div>
            </div>
          </div>
          <div class="container-third">
            <div
              :class="[
                'outside-section',
                gameLive.number >= 1 && gameLive.number <= 18 ? 'show' : '',
              ]"
            >
              <div>1-18</div>
            </div>
            <div
              :class="[
                'outside-section',
                gameLive.winner.includes('EVEN') ? 'show' : '',
              ]"
            >
              <div>EVEN</div>
            </div>
            <div
              :class="[
                'outside-section',
                gameLive.winner.includes('RED') ? 'show' : '',
              ]"
            >
              <div><div class="rhomb-red"></div></div>
            </div>
            <div
              :class="[
                'outside-section',
                gameLive.winner.includes('BLACK') ? 'show' : '',
              ]"
            >
              <div><div class="rhomb-black"></div></div>
            </div>
            <div
              :class="[
                'outside-section',
                gameLive.winner.includes('ODD') ? 'show' : '',
              ]"
            >
              <div>ODD</div>
            </div>
            <div
              :class="['outside-section', gameLive.number >= 19 ? 'show' : '']"
            >
              <div>19-36</div>
            </div>
          </div>
          <div class="mb-24"></div>

          <!-- <div>
            <p class="desc mt-20" style="text-align: center">
              โต๊ะที่ :
              {{ gamePrediction.tableId }} ตาที่ :
              {{ gamePrediction.gameRound }} วิเคราะห์ผล:
              {{ gamePrediction.prediction.playerType.toString() }}
            </p>
          </div> -->
        </div>
      </a-card>
    </section>
    <a-row :gutter="24" class="mt-10">
      <a-col :span="12" :lg="4" :xl="4">
        <div class="card-client">
          <p class="name-client">โต๊ะที่</p>
          <div class="social-media">
            <span> {{ gamePrediction.tableId }} </span>
          </div>
        </div>
      </a-col>
      <a-col :span="12" :lg="4" :xl="4">
        <div class="card-client">
          <p class="name-client">ตาที่</p>
          <div class="social-media">
            <span> {{ gamePrediction.gameRound }} </span>
          </div>
        </div>
      </a-col>
      <a-col :span="12" :lg="16" :xl="16" :xs="24" :md="24">
        <div class="card-client">
          <p class="name-client">วิเคราะห์ผล</p>
          <div class="social-media">
            {{ gamePrediction.prediction.playerType.toString() }}|{{
              gamePrediction.prediction.number
            }}
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  props: {
    tableId: {
      type: Number,
      default: 33,
    },
    gameType: { type: String, default: "ROULETTE" },
    gameState: { type: String, default: "WATTING" },
  },
  components: { PulseLoader },
  data() {
    return {
      red_number: [
        1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
      ],
      col3: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
      col2: [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35],
      col1: [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34],

      gamePrediction: {
        tableId: "-",
        gameRound: "-",
        prediction: { playerType: ["-"], number: "-" },
      },
      gameLive: {
        bankerCards: ["", "", ""],
        playerCards: ["", "", ""],
        bankerScore: 0,
        playerScore: 0,
        number: 0,
        winner: ["-"],
      },

      timer: null,
      setTimer: false,
    };
  },
  computed: {},
  async mounted() {},
  watch: {},
  methods: {
    updateBoard() {
      this.gameLive = {
        bankerCards: ["", "", ""],
        playerCards: ["", "", ""],
        bankerScore: 0,
        playerScore: 0,
        number: 0,
        winner: ["-"],
      };
      this.gamePrediction = {
        tableId: "-",
        gameRound: "-",
        prediction: { playerType: ["-"], number: "-" },
      };
    },
    getGameLive(val) {
      this.gameLive = val;
    },
    getGamePrediction(val) {
      this.gamePrediction = val;
    },
    isSetTimer(val) {
      this.setTimer = val;
    },

    isCountDown() {
      if (this.setTimer) {
        this.timer = 20;
        // this.countDownTimer();
      }
    },
  },
};
</script>
<style scoped>
.show {
  animation: mymove 5s ease 0s 1 normal forwards;
}
.hide {
  opacity: 0.4;
}

@keyframes mymove {
  0% {
    background-color: rgba(255, 0, 0, 0);
  }
  100% {
    background-color: #ffb700;
  }
}

.container-first {
  display: flex;
  flex-flow: column-reverse wrap;
  align-content: flex-start;
  width: auto;
  height: 110px;
  padding: 0px 0px 0px 20px;
  border-top: 2px sold white;
}

.red-item,
.black-item,
.zero-item {
  box-sizing: border-box;
  border: none;
  border-left: 3px solid white;
  border-top: 3px solid white;
  text-align: center;
  padding: 9px;
  width: 33px;
  font-size: 10px;
  color: white;
  height: 35px;
}
.zero-item {
  background-color: #029211;
  border-bottom: 3px solid white;
  padding-top: 41px;
  padding-bottom: 58px;
  border-radius: 8px 0px 0px 8px;
}
.red-item {
  background-color: #cc0001;
}

.black-item {
  background-color: black;
}

.column-item {
  border: solid white;
  background-color: #232323;
  padding: 8.5px;
  width: 60px;
  text-align: center;
  font-size: 10px;
  color: white;
  border-radius: 0px 2px 2px 0px;
}

.container-second {
  display: flex;
  width: auto;

  flex-flow: row wrap;
  align-content: flex-start;
  padding: 0px 0px 0px 56px;
}

.doz-item {
  flex-basis: 131px;
  border: solid white;
  background-color: #029211;
  padding-top: 15px;
  padding-bottom: 30px;
  text-align: center;
  height: 20px;
  font-size: 10px;
  color: white;
}

.container-third {
  display: flex;
  width: auto;

  flex-flow: row wrap;
  align-content: flex-start;
  padding: 0px 0px 0px 56px;
}

.outside-section {
  flex-basis: 65.5px;
  border: solid white;
  background-color: #029211;
  padding-top: 15px;
  padding-bottom: 30px;
  text-align: center;
  height: 20px;
  font-size: 10px;
  color: white;
  border-radius: 0px 0px 2px 2px;
}

.rhomb-red {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: red;
  border: solid white;
  transform: rotate(45deg);
}

.rhomb-black {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: black;
  border: solid white;
  transform: rotate(45deg);
}

.value {
}
.shadow {
  display: grid;

  color: #fff;
  position: relative;
  background: transparent;
}

.shadow::before,
.shadow::after {
  content: "";
  position: absolute;
  border-radius: 4px;
  background: linear-gradient(
    45deg,
    #ffffff,
    #ffffdc,
    #fff6d3,
    #ffffff,
    #ffffd2,
    #ffffff,
    #ffffbb,
    #ffffff
  );
  background-size: 800%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -100;
  animation: animate 25s;
}

.shadow::after {
  filter: blur(15px);
}
@media (max-width: 1396px) {
  .container-first {
    padding: 0px 0px 0px 0px;
  }

  .container-second {
    padding: 0px 0px 0px 36px;
  }

  .container-third {
    width: auto;
    padding: 0px 0px 0px 36px;
  }
}

@media (max-width: 1344px) {
  #rouletteBoard {
  }

  .red-item,
  .black-item,
  .zero-item {
    width: 30px;
    height: 30px;
  }
  .zero-item {
    padding-top: 34px;
    padding-bottom: 50px;
  }

  .column-item {
    padding: 6px;
  }

  .doz-item {
    flex-basis: 118px;
    padding-top: 10px;
    padding-bottom: 25px;
  }

  .outside-section {
    flex-basis: 59px;
    padding-top: 10px;
    padding-bottom: 25px;
  }
}
@media (max-width: 1248px) {
  .red-item,
  .black-item,
  .zero-item {
    width: 27px;
  }
  .zero-item {
    padding-top: 34px;
    padding-bottom: 50px;
  }

  .column-item {
    padding: 6px;
  }

  .doz-item {
    flex-basis: 104px;
    padding-top: 10px;
    padding-bottom: 25px;
  }

  .outside-section {
    flex-basis: 52px;
    padding-top: 10px;
    padding-bottom: 25px;
  }
}

@media (max-width: 1188px) {
  #rouletteBoard {
  }
  .container-first {
    height: 80px;
  }

  .red-item,
  .black-item,
  .zero-item {
    width: 10px;
    height: 10px;
    font-size: 8px;
  }
  .value {
    margin: -6px;
  }
  .zero-item {
    padding-top: 27px;
    padding-bottom: 30px;
  }

  .column-item {
    padding: 1.5px;
    width: 42px;
  }

  .doz-item {
    flex-basis: 78px;
  }

  .container-third {
    padding: 0px 0px 0px 36px;
  }

  .outside-section {
    flex-basis: 39px;
  }
}
@media (max-width: 991px) {
  .container-first {
    height: 110px;
    padding: 0px 0px 0px 20px;
  }

  .red-item,
  .black-item,
  .zero-item {
    padding: 9px;
    width: 33px;
    font-size: 10px;
    height: 35px;
  }
  .zero-item {
    border-bottom: 3px solid white;
    padding-top: 41px;
    padding-bottom: 58px;
    border-radius: 20px 0px 0px 20px;
  }

  .column-item {
    padding: 8.5px;
    width: 60px;
  }

  .container-second {
    padding: 0px 0px 0px 56px;
  }

  .doz-item {
    flex-basis: 131px;
  }

  .container-third {
    padding: 0px 0px 0px 56px;
  }

  .outside-section {
    flex-basis: 65.5px;
  }

  .value {
    margin: 0;
  }
}
@media (max-width: 562px) {
  #rouletteBoard {
  }
  .container-first {
    height: 80px;
    padding: 0px 0px 0px 0px;
  }

  .red-item,
  .black-item,
  .zero-item {
    width: 10px;
    height: 10px;
    font-size: 8px;
  }
  .value {
    margin: -6px;
  }
  .zero-item {
    padding-top: 27px;
    padding-bottom: 30px;
  }

  .column-item {
    padding: 1.5px;
    width: 42px;
  }

  .doz-item {
    flex-basis: 78px;
  }

  .container-third {
    padding: 0px 0px 0px 36px;
  }

  .outside-section {
    flex-basis: 39px;
  }
}
@media (max-width: 450px) {
  #rouletteBoard {
  }
  .container-first {
    height: 80px;
  }

  .red-item,
  .black-item,
  .zero-item {
    width: 10px;
    height: 10px;
    font-size: 8px;
  }
  .value {
    margin: -6px;
  }
  .zero-item {
    padding-top: 27px;
    padding-bottom: 30px;
  }

  .column-item {
    padding: 1.5px;
    width: 42px;
  }

  .doz-item {
    flex-basis: 78px;
  }

  .container-second {
    padding: 0px 0px 0px 36px;
  }
  .container-third {
    padding: 0px 0px 0px 36px;
  }

  .outside-section {
    flex-basis: 39px;
  }
}
@media (max-width: 370px) {
  #rouletteBoard {
  }
  .container-main {
    margin: -15px;
  }
  .container-first {
    height: 80px;
  }

  .red-item,
  .black-item,
  .zero-item {
    width: 5px;
    height: 5px;
    font-size: 6px;
  }
  .value {
    margin: -6px;
  }
  .zero-item {
    padding-top: 27px;
    padding-bottom: 30px;
  }

  .column-item {
    padding: 1.5px;
    width: 42px;
  }

  .doz-item {
    flex-basis: 78px;
  }

  .container-second {
    padding: 0px 0px 0px 36px;
  }
  .container-third {
    padding: 0px 0px 0px 36px;
  }

  .outside-section {
    flex-basis: 39px;
  }
}
</style>
