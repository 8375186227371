<template>
  <div>
    <p class="text-left" style="color: #f5222d" v-if="!validate">
      {{ customMsg || errorMsg }}
    </p>
  </div>
</template>
<script>
export default {
  components: {},
  computed: {},
  props: {
    errorMsg: { type: String, default: "*กรุณากรอกข้อมูล" },
  },
  mounted() {},

  data() {
    return {
      validate: true,
      customMsg: "",
    };
  },
  methods: {
    notValidate(val, msg = "") {
      this.validate = val;
      this.customMsg = msg;
    },
  },
};
</script>
