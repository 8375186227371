<template>
  <div>
    <a-col :span="24" :md="12" class="p-0 pr-5">
      <p class="m-0">จำนวนเงิน</p>
      <a-input
        type="number"
        placeholder="จำนวนเงิน"
        v-model.num="newModel.amount"
        @input="calculate('AMOUNT')"
      />
    </a-col>
    <a-col :span="24" :md="12" class="p-0">
      <p class="m-0">จำนวนเปอร์เซ็น</p>
      <a-input
        type="number"
        placeholder="จำนวนเปอร์เซ็น"
        v-model.num="newModel.percent"
        @input="calculate('PERCENT')"
      />
    </a-col>
    <br />
  </div>
</template>
<script>
export default {
  components: {},
  computed: {},
  props: {
    model: { type: Object, default: { amount: 0, percent: 0 } },
    action: { type: String, default: "" },
  },
  mounted() {
    this.newModel = this.model;
  },

  data() {
    return {
      newModel: {},
      validate: true,
    };
  },
  methods: {
    callAction() {
      this.$emit(this.action, this.newModel);
    },
    calculate(val) {
      if (val === "AMOUNT") {
        this.newModel.percent = parseFloat(
          (this.newModel.amount * 100) / this.$store.getters.getBalance
        ).toFixed(2);
      } else {
        this.newModel.amount = parseFloat(
          (this.$store.getters.getBalance * this.newModel.percent) / 100
        );
      }
      this.newModel.percent = parseFloat(this.newModel.percent);
      this.newModel.amount = parseFloat(this.newModel.amount);

      this.callAction();
    },
  },
};
</script>
